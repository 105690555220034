<template>
  <div>
    <!--    <el-radio-group v-for='item of typeList' @change='serchs(item.id)' disabled='true' v-model='labelPosition' size='small'>-->
    <!--      <el-radio-button style='margin-right: 10px;' :label='item.id'>{{ item.value }}</el-radio-button>-->
    <!--    </el-radio-group>-->

    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="招贤纳士" name="recruit">
        <el-button type="primary" icon="el-icon-plus" @click="userAddData"
          >新增</el-button
        >
        <div style="margin: 10px;"></div>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            type="index"
            label="序号"
            header-align="center"
            align="center"
            width="60"
            :index="indexMethod"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="名称"
          >
          </el-table-column>

    <!--      <el-table-column-->
    <!--        prop="jobRequirements"-->
    <!--        header-align="center"-->
    <!--        align="center"-->
    <!--        label="任职要求"-->
    <!--      >-->
    <!--      </el-table-column>-->
    <!--      <el-table-column-->
    <!--        prop="jobResponsibilities"-->
    <!--        header-align="center"-->
    <!--        align="center"-->
    <!--        label="岗位职责"-->
    <!--      >-->
    <!--      </el-table-column>-->
          <!--      <el-table-column-->
          <!--        prop="sortOrder"-->
          <!--        header-align="center"-->
          <!--        align="center"-->
          <!--        label="排序"-->
          <!--      >-->
          <!--        <template slot-scope="scope">-->
          <!--          <el-button-->
          <!--            icon="el-icon-bottom"-->
          <!--            size="small"-->
          <!--            @click="handleSort(scope.row.id, 'down')"-->
          <!--          ></el-button>-->
          <!--          <el-button-->
          <!--            icon="el-icon-top"-->
          <!--            size="small"-->
          <!--            @click="handleSort(scope.row.id, 'up')"-->
          <!--          ></el-button>-->
          <!--        </template>-->
          <!--      </el-table-column>-->
          <el-table-column
            prop="createDatetime"
            header-align="center"
            align="center"
            width="180"
            label="发布时间"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="240"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="handleEdit(scope.row.id)"
                >编辑
              </el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="handleDelete(scope.row.id)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px; height: 32px; width: 100%"></div>
        <el-dialog
          :title="this.title"
          :visible.sync="dialogFormVisible"
          :append-to-body="true"
          top="5vh"
          width="800px"
          @close="close('formName')"
        >
          <el-form :model="form" :rules="rules" ref="formName">
            <el-row>
              <el-col :span="12">
                <el-form-item label="职位名称：" class="form" prop="name">
                  <el-input
                    v-model="form.name"
                    placeholder="请输入职位名称"
                    class="formInput"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row> </el-row>
            <el-form-item
              label="任职要求："
              prop="jobRequirements"
              style="    margin-left: 8px;"
            >
              <vue-editor
                v-model="form.jobRequirements"
                style="width: 88%;
                margin-left: 95px;
                margin-top: 10px;"
                class="formInput"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </el-form-item>
            <el-form-item
              label="岗位职责："
              prop="jobResponsibilities"
              style="    margin-left: 8px;"
            >
              <vue-editor
                v-model="form.jobResponsibilities"
                style="width: 88%;
              margin-left: 95px;
                margin-top: 10px;"
                class="formInput"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </el-form-item>
            <el-form-item
              style="display: flex;flex-direction: row;justify-content: flex-end;margin: 10px 0;"
            >
              <el-button type="primary" @click="formSave('formName')"
                >确 定
              </el-button>
              <el-button @click="formCancel('formName')">取 消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="联系我们" name="contact">
        <contactUs />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  recruitInfoQueryList,
  recruitInfoDetails,
  recruitInfoDelete,
  uploadFile,
  webServerHttpUrl,
  recruitInfoUpdate,
  recruitInfoSave,
} from '@/api/joinAdmin'
import { VueEditor } from 'vue2-editor'
import { rotateList } from '../../api/rotationMap'
import contactUs from '@/pages/contactUs/index'
export default {
  components: { VueEditor, contactUs },
  data() {
    return {
      customToolbar: [
        // 富文本框配置文件
        ['bold', 'italic', 'color', 'background', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'video', 'code-block', 'code', 'link'],
        ['clean']
      ],
      web: null, // 图片路径
      imgList: [], // 图片数据
      dialogImageUrl: '', // 图片显示的路径
      title: null, // 表单弹窗标题
      tableData: [],
      typeList: [],
      labelPosition: 1,
      recordsTotal: null, // 数据总数
      pageInfo: {},
      dialogFormVisible: false, // 表单弹框开关
      dialogVisible: false,
      folder: {
        folder: 'rotatePictures'
      },
      current: 1,
      size: 10,
      params: {
        name: ''
      },
      form: {
        name: null,
        jobRequirements: null,
        jobResponsibilities: null
      },
      activeName: 'recruit',  // 标签页切换
      rules: {
        name: [{ required: true, message: '请输入职位名称', trigger: 'blur' }],
        jobRequirements: [
          { required: true, message: '请输入任职要求', trigger: 'blur' }
        ],
        jobResponsibilities: [
          { required: true, message: '请输入岗位职责', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // this.getList()
  },
  watch: {},
  mounted() {
    this.getList()
  },
  methods: {
    //清空表单
    formData() {
      this.form.name = null
      this.form.jobRequirements = null
      this.form.jobResponsibilities = null
    },
    indexMethod(index) {
      index = index + 1 + (this.current - 1) * this.size
      return index
    },
    getList() {
      recruitInfoQueryList().then(res => {
        this.tableData = res
      })
    },

    // 弹框关闭事件
    close(formName) {
      this.$refs[formName].resetFields()
      if (this.$refs.upload != null) {
        this.$refs.upload.clearFiles()
      }
    },
    //保存按钮
    formSave(formName) {
      console.log(this.form)
      let _this = this
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('您确定保存吗？', '信息提示', {
            cancelButtonClass: 'btn-custom-cancel',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              console.log(_this.form)
              if (this.form.id == null) {
                recruitInfoSave(_this.form).then(res => {
                  console.log(res)
                  this.$alert('保存成功！', '信息提示', {
                    confirmButtonText: '确定',
                    type: 'success'
                  }).then(() => {
                    this.getList()
                    this.formData()
                    this.dialogFormVisible = false
                    this.$refs[formName].resetFields()
                  })
                })
              } else {
                recruitInfoUpdate(_this.form).then(res => {
                  console.log(res)
                  this.$alert('保存成功！', '信息提示', {
                    confirmButtonText: '确定',
                    type: 'success'
                  }).then(() => {
                    this.formData()
                    this.getList()
                    this.dialogFormVisible = false
                    this.$refs[formName].resetFields()
                  })
                })
              }
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //新增
    userAddData() {
      this.imgList = []
      this.title = '新增'
      this.formData()
      this.dialogFormVisible = true
    },
    //编辑
    handleEdit(id) {
      this.imgList = []
      this.title = '编辑'
      this.formData()
      recruitInfoDetails(id).then(res => {
        this.form = res
        this.form.id = res.id
        this.imgList.push({
          url: res.picPathHttpUrl
        })
        this.dialogFormVisible = true
      })
    },
    //删除
    handleDelete(id) {
      this.$confirm('您确定删除吗？', '信息提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          recruitInfoDelete(id).then(res => {
            console.log(res)
            this.$alert('删除成功！', '信息提示', {
              confirmButtonText: '确定',
              type: 'success'
            }).then(() => {
              this.getList()
            })
          })
        })
        .catch(() => {})
    },
    // 取消
    formCancel(formName) {
      this.dialogFormVisible = false
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  margin: auto;
}

.form {
  width: 95%;
  display: inline-block;
  margin: 10px 10px;
}
/deep/.el-upload-list__item-actions {
  width: 200px;
  height: 100px;
}
.form1 {
  width: 100%;
  display: inline-block;
  margin: 13px 10px;
}

.formInput {
  width: 67%;
  /*margin-left: 16px;*/
}

/deep/ .el-dialog__body {
  margin-top: -19px;
}

/deep/ .ql-editor {
  min-height: 140px !important;
}
/deep/.el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  line-height: 80px;
  vertical-align: top;
}
/deep/.el-upload--picture-card {
  width: 70px;
  height: 70px;
  margin-top: 37px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 300px;
  height: 150px;
}
/deep/.el-upload-list__item-actions {
  width: 300px;
  height: 150px;
}

/deep/ #quill-container {
  height: 156px;
}
/deep/ [data-v-0dd82d4c] .ql-editor {
  min-height: 100px !important;
}
/*去除upload组件过渡效果*/
/deep/ .el-upload-list__item {
  transition: none !important;
}
</style>
