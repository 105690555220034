import {get,post} from "../http/index";

export const rotateQueryList = () => get('/rotate/queryList')

//轮播图分页不带查询
export const rotateList = (current, size, params) => post('/rotate/page?current=' + current + '&size=' + size, params)

export const newsQueryList = (current, size, params) => post('/news/page?current=' + current + '&size=' + size , params);

export const queryListForOrderByCodeId = (params) => get('/rotatePictures/queryListForOrderByCodeId/' + params)

export const queryListByTypeId = (params) => get('/dictionary/queryListByTypeId/' + params)
//删除
export const rotatePicturesDelete = (params) => get('/rotate/delete/' + params);
export const rotatePicturesSave = (params) => post('/rotatePictures/save',params)
export const rotatePicturesSoet = (id, upOrDown) =>get('/rotatePictures/sort/' + upOrDown + "/" + id);
//详情
export const rotatePicturesDetail = (params) => get('/rotate/detail/' + params);
//保存新增
export const messageInfoSave = (params) => post('/rotate/save',params)
// 编辑数据保存
export const messageInfoupdate = (params) => post('/rotate/update',params)

// 图片上传集合
export const uploadFile = (params, folder) => post('/fileProcessing/uploadFile', params, folder)

// 获取图片链接
export const webServerHttpUrl = () => get('/fileProcessing/webServerHttpUrl')

//轮播图排序
export const rotateSort = (id, upOrDown) =>get('/rotate/sort/' + upOrDown + "/" + id);
